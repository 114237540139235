import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        posts: [],
        pagination: {}
    },

    mutations: {
        ALL_POST(state, posts) {
            state.posts = posts
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
        DELETE_POST(state, id) {
            state.posts = state.posts.filter((x) => x.id !== id)
        },
    },

    actions: {
      // 
      async get_posts({commit}, page_data) {
          const resp = await Vue.prototype.$http.get('api/posts/list/?since_id=1&page='+page_data.page)
          commit('ALL_POST', resp.data.data.post.data)

          let total = resp.data.data.post.total
          let per_page = resp.data.data.post.per_page

          const pagination = {
              pages: Math.ceil(total/per_page),
              per_page: per_page,
              current_page: resp.data.data.post.current_page,
              last_page: resp.data.data.post.last_page,
              on_click: 'click_handler',
              total: total
          }

          commit('SET_PAGINATION', pagination)
          return resp
      },

      async delete_post({commit}, form_data) {
        const resp = await Vue.prototype.$http.delete(`/api/posts/delete/${form_data.id}`)
        if(resp.data) 
        {
            commit('DELETE_POST', form_data.id)
        }
        return resp
    },
    },

    getters: {
        allPosts: (state) => state.posts,
        getPagination: (state) => state.pagination
    },
}