import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default {
	install(Vue) {
		const requestInterceptor = (config) => {
			const token = localStorage.getItem("token");
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		};
		axios.interceptors.request.use(requestInterceptor, (err) =>
			Promise.reject(err)
		);
		Vue.prototype.$http = axios;
	},
};
