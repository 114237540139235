import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import events from './modules/event'
import posts from './modules/post'
import update_profiles from './modules/update-profile'
import update_images from './modules/update-images'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    events,
    posts,
    update_profiles,
    update_images
  }
});
