<template>
    <div class="main">
        <md-dialog :md-active.sync="showEditDialog" :md-close-on-esc="false" :md-click-outside-to-close="false">
            <md-dialog-title>Edit Event</md-dialog-title>
        <div style="margin:20px">
            <form >  
                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon>title</md-icon>
                    <label>Title...</label>
                    <md-input v-model="formData.title"  v-validate="'required'" type="title" data-vv-name="title" />
                    <span class="md-error">{{ errors.first('title') }}</span> 
                </md-field>
                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon>map</md-icon>
                    <label>Location...</label>
                    <md-input v-model="formData.location" v-validate="'required'" data-vv-name="location" type="text" />
                    <span class="md-error">{{ errors.first('location') }}</span>
                </md-field>

                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon>list</md-icon>
                    <label>Description...</label>
                    <md-textarea v-model="formData.description" v-validate="'required'" data-vv-name="description" md-autogrow> </md-textarea>
                    <span class="md-error">{{ errors.first('description') }}</span>
                </md-field>

                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon class="fas fa-calendar-day"></md-icon>
                    <label>Remaining Days...</label>
                    <md-input v-model="formData.remaining_days" v-validate="'required'" data-vv-name="remaining_days" type="number" />
                    <span class="md-error">{{ errors.first('remaining_days') }}</span>
                </md-field>
                    <md-datepicker v-model="formData.event_date" md-immediately v-validate="'required'" data-vv-name="event_date" type="number" />
                    <span class="md-error">{{ errors.first('event_date') }}</span>
                <md-field class="form-group md-invalid" slot="inputs">
                    <label>Only images</label>
                    <md-file  accept="image/*" data-vv-name="image" />
                </md-field>
            </form>
        </div>

        <md-dialog-actions>
            <md-button class="md-primary" @click="resetForm()">Close</md-button>
            <md-button class="md-primary" @click="EditEvent()">Update</md-button>
        </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
export default {
    data:() => ({
        showEditDialog:false,
        formData :{
            id:null,
            title:'',
            location:'',
            remaining_days:'',
            event_date:null,
            description:'',
            image:null
        }
    }),

    methods:{
        show(event) {
            this.showEditDialog = true
            this.formData.id = event.id
            this.formData.title = event.title
            this.formData.description = event.description
            this.formData.location = event.location
            this.formData.event_date = event.event_date
            this.formData.remaining_days = event.remaining_days
            this.formData.image = null
        },

        dateFormat(date) {
            var d = new Date(date);
            const newDate = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + (d.getDate())).slice(-2)
            ].join('-');
            return newDate
        },
        
        async EditEvent() {            
            const valid = await this.$validator.validateAll()
            if(valid) {
                this.formData.event_date = this.dateFormat(this.formData.event_date)
                let loader = this.$loading.show()
                try {
                    await this.$store.dispatch('events/update_event', this.formData)
                    await this.$store.dispatch("alerts/success", 'Event Update Successfully');
                    loader.hide()
                    this.showEditDialog = false
                } catch (e) {
                    loader.hide()
                    await this.$store.dispatch("alerts/error", e);
                }
            }
        },

        resetForm () {
          this.$store.getters['events/allEvents']    
          this.showEditDialog = false  
        }
    }
}
</script>

<style >
.md-dialog-container {
  min-width: 700px !important;
}
</style>