import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        profiles: [],
        pagination: {}
    },

    mutations: {
        ALL_PROFILE_UPDATE_LIST(state, profiles) {
            state.profiles = profiles
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
        UPDATE_PROFILE(state, profile) {
            var profiles = [...state.profiles]
            var index = profiles.findIndex(x => x.id === profile.id)
            if(index !== -1) {
                profiles[index] = profile
                state.profiles = profiles
            }
        },
        REJECT_PROFILE(state, id) {
            state.profiles = state.profiles.filter((x) => x.id !== id)
        },
    },

    actions: {
      // 
      async get_profile_update({commit}, page_data) {
          const resp = await Vue.prototype.$http.get('api/users/update-profile/list?page='+page_data.page)
          commit('ALL_PROFILE_UPDATE_LIST', resp.data.data.update_profiles.data)

          let total = resp.data.data.update_profiles.total
          let per_page = resp.data.data.update_profiles.per_page

          const pagination = {
              pages: Math.ceil(total/per_page),
              per_page: per_page,
              current_page: resp.data.data.update_profiles.current_page,
              last_page: resp.data.data.update_profiles.last_page,
              on_click: 'click_handler',
              total: total
          }

          commit('SET_PAGINATION', pagination)
          return resp
      },
      //
      async update_profile_status({commit}, formData) {
          const resp = await Vue.prototype.$http.post('api/users/update-profile/assert/'+formData.id)
          commit('UPDATE_PROFILE', resp.data.data)
      },
      //
      async update_profile_reject({commit}, formData) {
        const resp = await Vue.prototype.$http.post('api/users/update-profile/reject/'+formData.id)
        if(resp.data)
        {
            commit('REJECT_PROFILE', formData.id)
        }
      }
    },

    getters: {
        allProfiles: (state) => state.profiles,
        getPagination: (state) => state.pagination
    },
}