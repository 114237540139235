import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        images: [],
        pagination: {}
    },

    mutations: {
        ALL_IMAGES_LIST(state, images) {
            state.images = images
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
        UPDATE_IMAGE(state, image) {
            var images = [...state.images]
            var index = images.findIndex(x => x.id === image.id)
            if(index !== -1) {
                images[index] = image
                state.images = images
            }
        },
        REJECT_IMAGE(state, id) {
            state.images = state.images.filter((x) => x.id !== id)
        },
    },

    actions: {
      // 
      async get_images_update({commit}, page_data) {
          const resp = await Vue.prototype.$http.get('api/users/update-images/list?page='+page_data.page)
          commit('ALL_IMAGES_LIST', resp.data.data.update_images.data)

          let total = resp.data.data.update_images.total
          let per_page = resp.data.data.update_images.per_page

          const pagination = {
              pages: Math.ceil(total/per_page),
              per_page: per_page,
              current_page: resp.data.data.update_images.current_page,
              last_page: resp.data.data.update_images.last_page,
              on_click: 'click_handler',
              total: total
          }

          commit('SET_PAGINATION', pagination)
          return resp
      },
      //
      async update_images_status({commit}, formData) {
          const resp = await Vue.prototype.$http.post('api/users/update-images/assert/'+formData.id)
          commit('UPDATE_IMAGE', resp.data.data)
      },

      async update_images_reject({commit}, formData) {
        const resp = await Vue.prototype.$http.post('api/users/update-images/reject/'+formData.id)
        if(resp.data)
        {
            commit('REJECT_IMAGE', formData.id)
        }
      }
      
    },

    getters: {
        allImages: (state) => state.images,
        getPagination: (state) => state.pagination
    },
}