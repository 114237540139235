<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100">
      <div class="text-center"></div>
      <div class="text-center" style="margin-bottom: 48px;">
        <h5><strong></strong></h5>
      </div>
    </div>

    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="login">
        <login-card header-color="green">
          <h4 slot="title" class="title">Log in</h4>
          
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input v-model="email"  v-validate="'required|email'" type="email" data-vv-name="email" />
            <span class="md-error">{{ errors.first('email') }}</span>          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Password...</label>
            <md-input v-model="password" v-validate="'required'" data-vv-name="password" type="password" />
                 <span class="md-error">{{ errors.first('password') }}</span>
          </md-field>
          <md-button
            slot="footer"
            type="submit"
            class="md-simple md-success md-lg"
          >
            Lets Go
           </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
  import {LoginCard} from "@/components";

  export default {
    components: {
      LoginCard
    },

    data: () => ({
      email: "",
      password: "",
    }),

    methods: {
      async login() {
      const valid = await this.$validator.validateAll()
      if(valid) {

        const user = {
          email: this.email,
          password: this.password
        }
        let loader = this.$loading.show()
        try {
          await this.$store.dispatch("auth/login", user);
          await this.$store.dispatch("alerts/success", "Login User Successfully!");
          loader.hide()
          this.$router.push('/users')
        } catch (e) {
          loader.hide()
          await this.$store.dispatch("alerts/error", "Invalid credentials!");
        }
      }
    }
  }
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>

