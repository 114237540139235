import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        events: [],
        pagination: {}
    },

    mutations: {
        ADD_EVENT(state, events) {
            state.events.push(events)
        },
        ALL_EVENTS(state, events) {
            state.events = events
        },
        UPDATE_EVENT(state, event) {
            var events = [...state.events]
            var index = events.findIndex(x => x.id === event.id)
            if(index !== -1) {
                events[index] = event
                state.events = events
            }
        },
        DELETE_EVENT(state, id) {
            state.events = state.events.filter((x) => x.id !== id)
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
    },

    actions: {
        async add_event({commit}, form_data) {
            const resp = await Vue.prototype.$http.post('/api/events/create', form_data,{ headers: {'Content-Type': 'multipart/form-data'}})
            commit('ADD_EVENT', resp.data.data.event)
            return resp
        },
        //
        async update_event({commit}, form_data) {
            const resp = await Vue.prototype.$http.post(`/api/events/update/${form_data.id}`, form_data)
            commit('UPDATE_EVENT', resp.data)
            return resp
        },
       // 
        async get_events({commit}, page_data) {
          const resp = await Vue.prototype.$http.get('/api/events/list?page='+page_data.page)
          commit('ALL_EVENTS', resp.data.data.events.data)

          let total = resp.data.data.events.total
          let per_page = resp.data.data.events.per_page

          const pagination = {
              pages: Math.ceil(total/per_page),
              per_page: per_page,
              current_page: resp.data.data.events.current_page,
              last_page: resp.data.data.events.last_page,
              on_click: 'click_handler',
              total: total
          }

          commit('SET_PAGINATION', pagination)
          return resp
      },

      async delete_event({commit}, form_data) {
        const resp = await Vue.prototype.$http.delete('/api/events/delete/'+form_data.id)
        if(resp.data == 1)
        {
            commit('DELETE_EVENT', form_data.id)
        }
        return resp
    }
    },

    getters: {
        allEvents: (state) => state.events,
        getPagination: (state) => state.pagination
    },
}