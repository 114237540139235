import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// router setup
import routes from "./routes";

// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return {selector: to.hash};
    } else {
      return {x: 0, y: 0};
    }
  },
  linkExactActiveClass: "nav-item active"
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
	if (token) {
		if (to.meta.reqAuth && token) {
			next()
			return
		} else {
			next({ name: "users" })
		}
	}

	if (to.meta.reqGuest) {
		next()
		return
	}

	next({ name: 'Login' })
  

});

export default router;
