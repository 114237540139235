<template>
    <div class="main">
        <md-dialog :md-active.sync="showDialog" :md-close-on-esc="false" :md-click-outside-to-close="false">
            <md-dialog-title>Add Event</md-dialog-title>
        <div style="margin:20px">
            <form >  
                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon>title</md-icon>
                    <label>Title...</label>
                    <md-input v-model="formData.title"  v-validate="'required'" type="title" data-vv-name="title" />
                    <span class="md-error">{{ errors.first('title') }}</span> 
                </md-field>
                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon>map</md-icon>
                    <label>Location...</label>
                    <md-input v-model="formData.location" v-validate="'required'" data-vv-name="location" type="text" />
                    <span class="md-error">{{ errors.first('location') }}</span>
                </md-field>

                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon>list</md-icon>
                    <label>Description...</label>
                    <md-textarea v-model="formData.description" v-validate="'required'" data-vv-name="description" md-autogrow> </md-textarea>
                    <span class="md-error">{{ errors.first('description') }}</span>
                </md-field>

                <md-field class="form-group md-invalid" slot="inputs">
                    <md-icon class="fas fa-calendar-day"></md-icon>
                    <label>Remaining Days...</label>
                    <md-input v-model="formData.remaining_days" v-validate="'required'" data-vv-name="remaining_days" type="number" />
                    <span class="md-error">{{ errors.first('remaining_days') }}</span>
                </md-field>
                    <md-datepicker v-model="formData.event_date" md-immediately v-validate="'required'" data-vv-name="event_date" type="number" />
                    <span class="md-error date-error">{{ errors.first('event_date') }}</span>
                <md-field class="form-group md-invalid" slot="inputs">
                    <label>Only images</label>
                    <md-file  @md-change="fileHandler" accept="image/*" />
                </md-field>
            </form>
        </div>

        <md-dialog-actions>
            <md-button class="md-primary" @click="showDialog = false">Close</md-button>
            <md-button class="md-primary" @click="AddEvent()">Save</md-button>
        </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showDialog:false,
            now : new Date(),
            formData :{
                title:'',
                location:'',
                remaining_days:'',
                event_date:null,
                description:'',
                image:null
            }
        }
       
    },
    methods:{
        show() {
            this.showDialog = true
            this.formData.title = ''
            this.formData.description = ''
            this.formData.remaining_days = ''
            this.formData.event_date = null
            this.formData.image = null
            this.formData.location = ''
        },

        fileHandler (e) {
            this.formData.image = e[0];
            if((!this.formData.image.name.match(/.(jpg|jpeg|png|gif)$/i))) {
                this.$store.dispatch("alerts/error", 'Invalid Image Type');     
                this.formData.image = null
            }
        },

        dateFormat(date) {
            var d = new Date(date);
            const newDate = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + (d.getDate())).slice(-2)
            ].join('-');

            return newDate
        },
        
        async AddEvent() {
            const valid = await this.$validator.validateAll()
            if(valid) {
                let loader = this.$loading.show()
                try {
                    let form_data = new FormData();
                    form_data.append("title", this.formData.title);
                    form_data.append("description", this.formData.description);
                    form_data.append("remaining_days", this.formData.remaining_days);
                    form_data.append("event_date", this.dateFormat(this.formData.event_date));
                    form_data.append("location", this.formData.location);
                    form_data.append("image", this.formData.image);
                    
                    // form_data.append("form_data", JSON.stringify(this.formData));
                    await this.$store.dispatch('events/add_event', form_data)
                    await this.$store.dispatch("alerts/success", 'Event Add Successfully');
                    loader.hide()
                    this.showDialog = false
                } catch (e) {
                    loader.hide()
                    await this.$store.dispatch("alerts/error", e);
                }
            }
        }
    }
}
</script>

<style >
.md-dialog-container {
  min-width: 700px !important;
}
.date-error {
    color: #ff1744;
    font-size: 12px;
}
</style>