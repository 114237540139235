<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <LightBox :media="media" v-show="previewImage" :showThumbs="false" :key="key"></LightBox>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon >image</md-icon>
          </div>
          <h4 class="title">Update Image Listing</h4>
        </md-card-header>
        <md-card-content>
        <div class="text-right">
            <!-- <md-button class="md-primary md-dense" @click="onProFeature">
              Add User
            </md-button> -->
          </div>
          <md-table
            :value="images"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }" >
              <md-table-cell md-label="Image">
                <a href="#" @click="previewImageHandler(item.images)">Preview</a>
              </md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="name">{{
                item.user.name
              }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.user.email
              }}</md-table-cell>
              <md-table-cell md-label="Type" md-sort-by="type">{{
                item.user.type
              }}</md-table-cell>
              <md-table-cell md-label="Actions" md-sort-by="actions">
                <md-button
                  :class="`md-icon-button md-raised md-round ${item.status == 0 ? 'md-success' : 'md-info'}`"
                  style="margin: 0.2rem"
                  @click="statusHandler(item, 'approve')"
                >
                  <md-icon>{{item.status == 0 ? 'check' : 'close'}}</md-icon>
                </md-button>
                <md-button
                  @click="statusHandler(item, 'reject')"
                  class="md-icon-button md-raised md-round md-danger"
                  style="margin: 0.2rem"
                >
                  <md-icon >close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

          <md-dialog-confirm
              :md-active.sync="active_confirm"
              :md-title="`Approve`"
              :md-content="`Are you sure you want to approve this image update request?`"
              md-confirm-text="Agree"
              md-cancel-text="Disagree"
              @md-cancel="onCancel"
              @md-confirm="onConfirm" 
          />

          <md-dialog-confirm
              :md-active.sync="reject_form"
              :md-title="`Reject`"
              :md-content="`Are you sure you want to reject the image update request?`"
              md-confirm-text="Agree"
              md-cancel-text="Disagree"
              @md-cancel="onCancel"
              @md-confirm="onConfirmReject" 
          />

        <md-card-actions md-alignment="space-between" >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} entries
            </p>
          </div>   
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.current_page"
            :per-page="pagination.per_page"
            :total="pagination.total"
            @input="getUpdateProfiles"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LightBox from 'vue-image-lightbox'

export default {
  components: {
    pagination: Pagination,
    LightBox
  },

  data: () => ({
    query: null,
    previewImage: false,
    active_confirm: false,
    reject_form: false,
    user: {},
    media:[],
    key:12
  }),

  computed: {
    images () {
      const temp = [];
      const response =  this.$store.getters['update_images/allImages'] 
      response.forEach(element => {
          if(element.status ==0) 
          {
            temp.push(element) 
          }
      });
      
      return temp
    },
    pagination () {
      return this.$store.getters['update_images/getPagination']    
    },
    from() {
      return this.pagination.per_page * (this.pagination.current_page - 1);
    },

    to() {
      let highBound = this.from + this.pagination.per_page;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
  },

  methods: {
    async getUpdateProfiles(page = 0) {
        let formData = {
          page: page
        }
        let loader = this.$loading.show()
        try {
            await this.$store.dispatch('update_images/get_images_update', formData)
            loader.hide()
        }
        catch (e) 
        {
          let message
          if (e.response) {
            if(e.response.status == 401) {
              message = e.response.data.message
              localStorage.removeItem('token')
              this.$router.push('/')
            }
            else
            {
              message = e.response.data.message
            }
          }
          else
          {
            message = e.toString()
          }
          loader.hide()
          await this.$store.dispatch("alerts/error", message);      
        }
    },

    statusHandler (user, type) {
      if(type == 'approve')
      {
        this.active_confirm = true
      }
      else
      {
        this.reject_form = true
      }
      this.user = user
    },

    async onConfirm () {
      let formData = {
        id:this.user.id
      }
      let loader = this.$loading.show()
      try {
        await this.$store.dispatch('update_images/update_images_status', formData)
        await this.$store.dispatch('alerts/success', 'Update Image approve Successfully')
        loader.hide()
      } catch (e) {
        loader.hide()
        await this.$store.dispatch("alerts/error", e.response.data.message);
      }
      this.active_confirm = false

    },

    async onConfirmReject () {
        const formData = {
          id:this.user.id
        }
        let loader = this.$loading.show()
        try {
          await this.$store.dispatch('update_images/update_images_reject', formData)
          await this.$store.dispatch('alerts/success', 'Update Image Reject Successfully')
          loader.hide()
        } catch (e) {
          loader.hide()
          await this.$store.dispatch("alerts/error", e.toString());
        }
        this.reject_form = false
    },

    onCancel() {
      this.active_confirm = false
      this.reject_form = false
      this.user = {}
    },

    placeHolder(e) {
      e.target.src =process.env.VUE_APP_BASE_URL + "/img/placeholder.jpg"
    },

    previewImageHandler( data) {
       this.key = Math.random(0,100)
      const response = JSON.parse(data)
      this.media = []
      response.forEach(ele => {
          this.media.push(
            {
              src:ele,
              caption:'update images',
              srcset: ele
            }
          )
      })
      this.previewImage = true
    }
  },

   created() {
      this.getUpdateProfiles()
  },
};
</script>
<style>
#pro-feature {
  font-weight: bold;
}
.vue-lb-container{
   z-index:20000 !important
}
</style>
