<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon >event</md-icon>
          </div>
          <h4 class="title">Event Listing</h4>
        </md-card-header>
        <md-card-content>
        <div class="text-right">
            <md-button class="md-primary md-dense" @click="addEventHandler()">
              Add Event
            </md-button>
          </div>
          <md-table
            :value="events"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell>
                <md-avatar v-if="item.image">
                    <img :src="item.image" alt="Avatar" @error="placeHolder">
                </md-avatar>
                <md-avatar v-else>
                  <img :src="defaultImage" alt="Avatar">
                </md-avatar>
              </md-table-cell>
              <md-table-cell md-label="Title" md-sort-by="title">{{
                item.title
              }}</md-table-cell>
               <md-table-cell md-label="Location" md-sort-by="location">{{
                item.location
              }}</md-table-cell>
              <md-table-cell md-label="Description" md-sort-by="description">{{
                item.description
              }}</md-table-cell>
              <md-table-cell md-label="Event Date" md-sort-by="event_date">{{
                item.event_date
              }}</md-table-cell>
               <md-table-cell md-label="Actions">
                <md-button
                 @click="edit(item)"
                  class="md-icon-button md-raised md-round md-info"
                  style="margin: 0.2rem"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  @click="statusHandler(item)"
                  class="md-icon-button md-raised md-round md-danger"
                  style="margin: 0.2rem"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-dialog-confirm
            :md-active.sync="active_confirm"
            :md-title="`Delete`"
            :md-content="`Are you sure you want to delete it`"
            md-confirm-text="Agree"
            md-cancel-text="Disagree"
            @md-cancel="onCancel"
            @md-confirm="onConfirm" 
        />

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} entries
            </p>
          </div>  
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.current_page"
            :per-page="pagination.per_page"
            :total="pagination.total"
            @input="getEvents"
          />
        </md-card-actions>
      </md-card>
    </div>
    <AddEvent ref="add_event"/>
    <EditEvent ref="edit_event" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import AddEvent from "../Events/AddEvent.vue"
import EditEvent from "../Events/EditEvent.vue"
export default {
  components: {
    pagination: Pagination,
    AddEvent,
    EditEvent
  },

  data: () => ({
    query: null,
    event:{},
    active_confirm: false,
      defaultImage:
        process.env.VUE_APP_BASE_URL + "/img/test.jpg",
  }),

  computed: {
    events () {
      return this.$store.getters['events/allEvents']    
    },
    pagination () {
      return this.$store.getters['events/getPagination']    
    },
    from() {
      return this.pagination.per_page * (this.pagination.current_page - 1);
    },

    to() {
      let highBound = this.from + this.pagination.per_page;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
  },

  methods: {
    async getEvents(page = 0) {
        let formData = {
          page: page
        }
        let loader = this.$loading.show()
        try {
            await this.$store.dispatch('events/get_events', formData)
            loader.hide()
        }
        catch (e) 
        {
          let message
          if (e.response) {
            if(e.response.status == 401) {
              message = e.response.data.message
              localStorage.removeItem('token')
              this.$router.push('/')
            }
            else
            {
              message = e.response.data.message
            }
          }
          else
          {
            message = e.toString()
          }
          loader.hide()
          await this.$store.dispatch("alerts/error", message);      
        }
    },

    addEventHandler () {
      this.$refs.add_event.show()
    },

    edit(item) {
      let user = item
      this.$refs.edit_event.show(user)
    },

    placeHolder(e) {
      e.target.src =process.env.VUE_APP_BASE_URL + "/img/test.jpg"
    },

    statusHandler (event) {
      this.active_confirm = true
      this.event = event
    },

    async onConfirm () {
      let formData = {
        id:this.event.id
      }
      let loader = this.$loading.show()
      try {
        await this.$store.dispatch('events/delete_event', formData)
        await this.$store.dispatch("alerts/success", 'Event Delete Successfully');
        loader.hide()
      } catch (e) {
        loader.hide()
        await this.$store.dispatch("alerts/error", e.response.data.message);
      }
      this.active_confirm = false

    },

    onCancel() {
      this.active_confirm = false
      this.user = {}
    },
  },
  
   created() {
      this.getEvents()
  },
};
</script>
<style>
#pro-feature {
  font-weight: bold;
}
</style>

