<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon >list</md-icon>
          </div>
          <h4 class="title">Post Listing</h4>
        </md-card-header>
        <md-card-content>
        <div class="text-right">
            <!-- <md-button class="md-primary md-dense" @click="onProFeature">
              Add User
            </md-button> -->
          </div>
          <md-table
            :value="posts"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell>
                <md-avatar>
                    <img :src="item.image" alt="Avatar" @error="placeHolder">
                </md-avatar>
            </md-table-cell>
              <md-table-cell md-label="Title" md-sort-by="title">
                  {{
                item.title
              }}</md-table-cell>
              <md-table-cell md-label="Description" md-sort-by="description">{{
                item.description
              }}</md-table-cell>
              <md-table-cell md-label="User" md-sort-by="user">{{
                item.user.name
              }}</md-table-cell>
                <md-table-cell md-label="Profile id" md-sort-by="profile_id">{{
                item.user.profile_id
              }}</md-table-cell>
              <md-table-cell md-label="Company" md-sort-by="company">{{
                item.user.company
              }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                @click="deletePost(item.id)"
                  class="md-icon-button md-raised md-round md-danger"
                  style="margin: 0.2rem"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-dialog-confirm
            :md-active.sync="active_confirm"
            :md-title="`Delete Pots`"
            :md-content="`Are you sure you want to delete the post`"
            md-confirm-text="Agree"
            md-cancel-text="Disagree"
            @md-cancel="onCancel"
            @md-confirm="onConfirm" 
        />
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} entries
            </p>
          </div>  
          <pagination
            v-if="posts"
            class="pagination-no-border pagination-success"
            v-model="pagination.current_page"
            :per-page="pagination.per_page"
            :total="pagination.total"
            @input="getPosts"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: {
    pagination: Pagination,
  },

  data: () => ({
    query: null,
    active_confirm: false,
    post_id: null
  }),

  computed: {
    posts () {
      return this.$store.getters['posts/allPosts']    
    },
    pagination () {
      return this.$store.getters['posts/getPagination']    
    },
    from() {
      return this.pagination.per_page * (this.pagination.current_page - 1);
    },

    to() {
      let highBound = this.from + this.pagination.per_page;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
  },

  methods: {
    async getPosts(page = 0) {
        let formData = {
          page: page
        }
        let loader = this.$loading.show()
        try {
            await this.$store.dispatch('posts/get_posts', formData)
            loader.hide()
        }
        catch (e) 
        {
          let message
          if (e.response) {
            if(e.response.status == 401) {
              message = e.response.data.message
              localStorage.removeItem('token')
              this.$router.push('/')
            }
            else
            {
              message = e.response.data.message
            }
          }
          else
          {
            message = e.toString()
          }
          loader.hide()
          await this.$store.dispatch("alerts/error", message);     
        }
    },

    deletePost(id){
      this.active_confirm = true
      this.post_id = id
    },

    onCancel() {
      this.active_confirm = false
      this.post_id = null
    },

    async onConfirm () {
      let formData = {
        id:this.post_id
      }
      let loader = this.$loading.show()
      try {
        await this.$store.dispatch('posts/delete_post', formData)
        await this.$store.dispatch("alerts/success", 'Post Delete Successfully');
        loader.hide()
      } catch (e) {
        loader.hide()
        await this.$store.dispatch("alerts/error", e.response.data.message);
      }
      this.active_confirm = false

    },

    placeHolder(e) {
      e.target.src =process.env.VUE_APP_BASE_URL + "/img/test.jpg"
    }
  },

   created() {
      this.getPosts()
  },
};
</script>
<style>
#pro-feature {
  font-weight: bold;
}
</style>
