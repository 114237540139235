import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        users: [],
        pagination: {}
    },

    mutations: {
        ALL_USERS(state, users) {
            state.users = users
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
        UPDATE_USER(state, user) {
            var users = [...state.users]
            var index = users.findIndex(x => x.id === user.id)
            if(index !== -1) {
                users[index] = user
                state.users = users
            }
        },
    },

    actions: {
      // 
      async get_users({commit}, page_data) {
          const resp = await Vue.prototype.$http.get('/api/users/list?page='+page_data.page)
          commit('ALL_USERS', resp.data.data.users.data)

          let total = resp.data.data.users.total
          let per_page = resp.data.data.users.per_page

          const pagination = {
              pages: Math.ceil(total/per_page),
              per_page: per_page,
              current_page: resp.data.data.users.current_page,
              last_page: resp.data.data.users.last_page,
              on_click: 'click_handler',
              total: total
          }

          commit('SET_PAGINATION', pagination)
          return resp
      },
      //
      async update_user_status({commit}, formData) {
          const resp = await Vue.prototype.$http.post('api/users/update-status/'+formData.id, {status:formData.status})
          commit('UPDATE_USER', resp.data)
      }
    },

    getters: {
        allUsers: (state) => state.users,
        getPagination: (state) => state.pagination
    },
}