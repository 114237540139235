<template>
  <form ref="password_form" @submit.prevent="changePassword">

    <md-card>

      <md-card-header class="md-card-header-icon">
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">
          Change Password
        </h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field class="md-invalid">
              <label>New Password</label>
              <md-input v-model="password" type="password"/>
\            </md-field>
            <md-field class="md-invalid">
              <label>Confirm New Password</label>
              <md-input v-model="password_confirmation" type="password"/>
            </md-field>
          </div>
        </div>
      </md-card-content>

      <md-card-actions>
        <md-button type="submit">
          Change Password
        </md-button>
      </md-card-actions>
    </md-card>

  </form>
</template>

<script>
  export default {
    name: "edit-password-card",
    props: {
      user: Object
    },

    data: () => ({
      password: null,
      password_confirmation: null
    }),

    methods: {
      async changePassword() {
        // console.log('object');
      }
    }
  };
</script>
