import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
// Profile
import UserProfile from "@/pages/Dashboard/Examples/UserProfile.vue";

// Users 
import UsersListing from "@/pages/Dashboard/Users/UserListing.vue"
// Posts listing

import PostListing from "@/pages/Dashboard/posts/PostListing.vue"

// evnets 
import EventListing from "@/pages/Dashboard/Events/EventListing.vue"
// Pages
import Login from "@/pages/Dashboard/Pages/Login.vue";
// update profile
import UpdateProfileListing from "@/pages/Dashboard/UpdateProfile/UpdateProfileListing.vue";
// update profile
import UpdateImageListing from "@/pages/Dashboard/UpdateImages/UpdateImageLists.vue";
// Components pages
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";


let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/notification",
  name: "Components",
  children: [
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: { reqAuth: true, }
    },
  ]
};

let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: {
        reqAuth: true,
      }
    },
  ]
};

let user_menu = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path:'/users',
      components:{default :UsersListing},
      name:'users',
      meta: {
        reqAuth: true,
      }
    },
  ]
};

let event_menu = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path:'/events',
      components:{default :EventListing},
      name:'events',
      meta: {
        reqAuth: true,
      }
    },
  ]
};

let post_menu = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path:'/posts',
      components:{default :PostListing},
      name:'posts',
      meta: {
        reqAuth: true,
      }
    },
  ]
};

let update_profile_menu = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path:'/update-profiles',
      components:{default :UpdateProfileListing},
      name:'Update Profiles',
      meta: {
        reqAuth: true,
      }
    },
  ]
};

let update_assets_image = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path:'/update-images',
      components:{default :UpdateImageListing},
      name:'Update Images',
      meta: {
        reqAuth: true,
      }
    },
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { reqGuest: true }
    },
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  {
    path: "/",
    component: DashboardLayout,
    meta: {reqAuth: true, },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: {
          reqAuth: true,
        }
      }
    ]
  },
  componentsMenu,
  examplesMenu,
  authPages,
  user_menu,
  event_menu,
  post_menu,
  update_profile_menu,
  update_assets_image
];

export default routes;
