import Vue from 'vue'

export default {
    namespaced: true,
    users:{},
    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status
    },
    state: {
        status: '',
	    	token: localStorage.getItem('token')
    },
    mutations: {
      AUTH_SUCCESS(state, token) {
        state.status = 'success'
        state.token = token
      },
      SET_USER(state, user) {
        state.user = user
      }
    },
    actions: {

		async login({commit}, form_data) {
			const resp = await Vue.prototype.$http.post('/api/login', form_data)
			const token = resp.data.data.token
			localStorage.setItem('token', token)
			commit('AUTH_SUCCESS', token)
      localStorage.setItem('user', JSON.stringify(resp.data.data.user))
			return resp
		},

		async logout({commit}) {
			const resp = await Vue.prototype.$http.post('/api/logout')
			localStorage.removeItem('token')
			return resp
		},
  },
  getters:{
    getUser: (state) => state.user
  }
}