<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon >person</md-icon>
          </div>
          <h4 class="title">User Listing</h4>
        </md-card-header>
        <md-card-content>
        <div class="text-right">
            <!-- <md-button class="md-primary md-dense" @click="onProFeature">
              Add User
            </md-button> -->
          </div>
          <md-table
            :value="users"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Phone" md-sort-by="phone">{{
                item.phone
              }}</md-table-cell>
              <md-table-cell md-label="Type" md-sort-by="type">{{
                item.type
              }}</md-table-cell>
              <md-table-cell md-label="Talent ID" md-sort-by="talent_id">{{
                item.profile_id
              }}</md-table-cell>
              <md-table-cell md-label="Status" md-sort-by="status">
                <md-button
                  :class="`md-icon-button md-raised md-round ${item.status == 0 ? 'md-danger' : 'md-info'}`"
                  style="margin: 0.2rem"
                  @click="statusHandler(item)"
                >
                  <md-icon>{{item.status == 0 ? 'close' : 'check'}}</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-dialog-confirm
            :md-active.sync="active_confirm"
            :md-title="`${user.name}`"
            :md-content="`Are you sure you want to ${user.status == 0 ? 'Active' : 'InActive'} the user`"
            md-confirm-text="Agree"
            md-cancel-text="Disagree"
            @md-cancel="onCancel"
            @md-confirm="onConfirm" 
        />
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} entries
            </p>
          </div>  
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.current_page"
            :per-page="pagination.per_page"
            :total="pagination.total"
            @input="getUsers"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: {
    pagination: Pagination,
  },

  data: () => ({
    query: null,
    active_confirm: false,
    user: {}
  }),

  computed: {
    users () {
      return this.$store.getters['users/allUsers']    
    },
    pagination () {
      return this.$store.getters['users/getPagination']    
    },
    from() {
      return this.pagination.per_page * (this.pagination.current_page - 1);
    },

    to() {
      let highBound = this.from + this.pagination.per_page;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
  },

  methods: {
    async getUsers(page = 0) {
        let formData = {
          page: page
        }
        let loader = this.$loading.show()
        try {
            await this.$store.dispatch('users/get_users', formData)
            loader.hide()
        }
        catch (e) 
        {
          let message
          if (e.response) {
            if(e.response.status == 401) {
              message = e.response.data.message
              localStorage.removeItem('token')
              this.$router.push('/')
            }
            else
            {
              message = e.response.data.message
            }
          }
          else
          {
            message = e.toString()
          }
          loader.hide()
          await this.$store.dispatch("alerts/error", message);     
        }
    },

    statusHandler (user) {
      this.active_confirm = true
      this.user = user
    },

    async onConfirm () {
      let formData = {
        status:this.user.status == 1 ? 0 : 1,
        id:this.user.id
      }
      let loader = this.$loading.show()
      try {
        await this.$store.dispatch('users/update_user_status', formData)
        loader.hide()
      } catch (e) {
        loader.hide()
        await this.$store.dispatch("alerts/error", e.response.data.message);
      }
      this.active_confirm = false

    },

    onCancel() {
      this.active_confirm = false
      this.user = {}
    },
  },

   created() {
      this.getUsers()
  },
};
</script>
<style>
#pro-feature {
  font-weight: bold;
}
</style>
